import React from "react"

import Layout from "../components/Layout/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Página não encontrada" />
    <h1>Ups, página não encontrada</h1>
    <p>Volta</p>
  </Layout>
)

export default NotFoundPage
